import { Controller } from "@hotwired/stimulus";

// data-controller="send-conversion-to-floodlight"
// data-send-conversion-to-floodlight-target="{{Insert target name here}}"
// data-send-conversion-to-floodlight-{{Insert value name here}}-value="{{Insert value here}}"
// data-action="{{Insert event here}}->send-conversion-to-floodlight#{{Insert function name here}}"
export default class SendConversionToFloodlightController extends Controller {
  submit() {
    if (window.gtag === undefined) { return; }
    if (this.element.getAttribute("action") !== "/natwest") { return; }

    window.gtag("event", "conversion", {
      allow_custom_scripts: true,
      send_to: "DC-8602179/nwfrag/freea003+standard",
    });
  }
}
