import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["copilotMoreInfo", "submitButton"];

  updateEventLabel() {
    const eventLabel = this.copilotMoreInfoTarget.checked ? "Checked" : "Unchecked";

    this.submitButtonTarget.dataset.eventLabel = eventLabel;
  }
}
