/* eslint no-restricted-imports: ["off", "@hotwired/turbo"] */

import { Application } from "@hotwired/stimulus";
import "@hotwired/turbo";
import { definitions as sharedContext } from "stimulus:../stimulus_controllers/shared";
import { definitions as signupContext } from "stimulus:../stimulus_controllers/signup";
import { definitions as freestyleComponents } from "stimulus:../../../engines/freestyle/app/components/";
import { registerStreamActions } from "stream_actions:../actions";
import { register } from "@fac/freestyle-ui";
import configureTurboConfirm from "shared/turbo_confirm";

const application = Application.start();

[sharedContext, signupContext, freestyleComponents].forEach((ctx) => {
  application.load(ctx);
});

register(application);
registerStreamActions();

application.debug = document.body.dataset.stimulusDebug === "true";

configureTurboConfirm(window.Turbo);
