import { Controller } from "@hotwired/stimulus";

const HIDDEN_CLASS = "fe-u-hidden";
const hideElement = element => element.classList.add(HIDDEN_CLASS);
const showElement = element => element.classList.remove(HIDDEN_CLASS);
const EXEMPT_COMPANY_TYPES = ["UK Limited Company", "UK Limited Liability Partnership"];

export default class MarketingConsentController extends Controller {
  static targets = [
    "marketingConsentFields",
    "marketingConsentTrue",
    "businessType",
    "impliedConsentCopy",
    "specifiedConsentCopy",
  ];

  connect() {
    this.toggleMarketingConsentForBusinessType();
  }

  toggleMarketingConsentForBusinessType() {
    if (EXEMPT_COMPANY_TYPES.includes(this.businessTypeTarget.value)) {
      this.applyImpliedConsent();
      this.selectMarketingConsent();
    } else {
      this.clearMarketingConsent();
      this.applySpecifiedConsent();
    }
  }

  applyImpliedConsent() {
    hideElement(this.marketingConsentFieldsTarget);
    hideElement(this.specifiedConsentCopyTarget);
    showElement(this.impliedConsentCopyTarget);
  }

  applySpecifiedConsent() {
    showElement(this.marketingConsentFieldsTarget);
    showElement(this.specifiedConsentCopyTarget);
    hideElement(this.impliedConsentCopyTarget);
  }

  selectMarketingConsent() {
    this.marketingConsentTrueTarget.checked = true;
  }

  clearMarketingConsent() {
    this.marketingConsentTrueTarget.checked = false;
  }
}
